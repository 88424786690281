import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'

const PageLegal = props => {

  return (
    <Layout {...props}>
      <Seo title='Legal' />
      <Divider />
      
     
      <Stack effectProps={{ effect: 'fadeInDown' }}>
      <PageTitle
          header='Descargo Legal de Cashfy'
          subheader='
          
          <b>Fecha de última actualización:</b> 20/08/2022<br><br>
          
          <b>Aviso Legal</b><br><br>
          
          Cashfy ("nosotros", "nuestro" o "sitio web") proporciona contenido informativo y educativo relacionado con finanzas en Argentina. El contenido en nuestro sitio web se proporciona "tal cual" y no garantizamos su exactitud, integridad o idoneidad para un propósito particular. Toda la información está sujeta a cambios sin previo aviso.<br><br>
          
          <b>Asesoramiento Financiero</b><br><br>
          
          Nada de lo contenido en Cashfy constituye asesoramiento financiero, legal o de inversión. No somos asesores financieros ni profesionales en derecho o inversión. Siempre se recomienda buscar el consejo de un profesional calificado antes de tomar decisiones financieras basadas en la información proporcionada en este sitio.<br><br>
          
          <b>Enlaces a Terceros</b><br><br>
          
          Nuestro sitio web puede contener enlaces a sitios web de terceros. No tenemos control sobre el contenido o las políticas de privacidad de estos sitios y no nos hacemos responsables de ellos.<br><br>
          
          <b>Responsabilidad</b><br><br>
          
          En la medida máxima permitida por la ley, Cashfy y sus colaboradores no serán responsables por ningún daño directo, indirecto, incidental, especial, consecuente o punitivo, ni por pérdidas de beneficios, ingresos, datos o pérdidas comerciales relacionadas con el uso de este sitio web.<br><br>
          
          <b>Jurisdicción y Ley Aplicable</b><br><br>
          
          Este descargo legal se rige por las leyes de la República Argentina y cualquier disputa que surja estará sujeta a la jurisdicción exclusiva de los tribunales argentinos.<br><br>
          
          <b>Actualizaciones del Descargo Legal</b><br><br>
          
          Nos reservamos el derecho de actualizar este descargo legal en cualquier momento para reflejar cambios en nuestras prácticas o para cumplir con las leyes y regulaciones aplicables. Cualquier actualización se publicará en esta página con una fecha de "última actualización" revisada.<br><br>
          
          Si tienes alguna pregunta sobre este descargo legal, por favor contáctanos a través de info@Cashfy.us.<br><br>
          
          Gracias por utilizar Cashfy.<br><br>
          
          '
        />
      </Stack>

    </Layout>
  )
}

export default PageLegal
